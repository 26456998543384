export const environment = {
  production: false,
  debug: false,
  apiUrl: 'https://hgp-uat-weu-app-01.azurewebsites.net',
  issuer: 'https://login.microsoftonline.com/0d7c2487-28ef-40cc-af4b-2e1abc1f01a4/v2.0',
  clientId: 'fe8b0e93-661b-43df-9641-fa1a740134d9',
  scope: 'openid profile fe8b0e93-661b-43df-9641-fa1a740134d9/default',
  showDebugInformation: true,

};
